@import 'styles/theme/light.scss';
@import 'primeicons/primeicons.css';
@import 'styles/theme/override.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-family: var(---font-family) !important;
}

:root {
  font-size: 14px;
  font-family: var(--font-family) !important;
}

@media (min-width: 1600px) {
  body {
    font-size: 14px;
  }
  :root {
    font-size: 14px;
  }
}

@media (max-width: 1599px) {
  body {
    font-size: 12px;
  }
  :root {
    font-size: 12px;
  }
}

ul {
  padding-inline-start: 0;
}

@layer tailwind-base, primereact, tailwind-utilities;

/* IMPORTANT: In "styled" mode you must add the PrimeReact Theme here. Do NOT include in "unstyled" mode */
@import 'primereact/resources/themes/lara-light-blue/theme.css'
  layer(primereact);

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .gradient-border {
    background:
      linear-gradient(white, white) padding-box,
      linear-gradient(
          0deg,
          var(--primary-gradient-from),
          var(--primary-gradient-to)
        )
        border-box;
    border: 2px solid transparent;
    border-radius: 8px;
  }

  .gradient-text {
    background-image: linear-gradient(
      0deg,
      var(--primary-gradient-from),
      var(--primary-gradient-to)
    );
    color: transparent;
    background-clip: text;
  }
}

@layer components {
  .card {
    @apply rounded-c8 shadow-c bg-white p-[16px];
  }
}

tbody td {
  padding: 16px 16px 16px 8px;
  @apply h-[72px] text-neutral-content-text2 text-sm-regular;
}

tbody tr {
  @apply border-t-[1px] border-neutral-border;
}

th {
  padding: 0px 16px 0px 8px;
  @apply border-y-[1px] border-neutral-border text-xs-medium h-[48px];
}

table .p-checkbox-box {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid;
  @apply border-neutral-4;
}

.p-datatable {
  @apply rounded-c8 shadow-c bg-white px-0 pb-[10px];
}

.p-datatable-header {
  @apply rounded-t-[8px] shadow-c bg-white;
}

.p-datatable-loading-overlay {
  @apply bg-transparent;
}

.p-datatable-loading-icon {
  @apply text-primary;
}

.p-selection-column {
  padding-right: 12px;
  padding-left: 20px;
}

.p-checkbox-box {
  width: 16px;
  height: 16px;
}

.p-checkbox-input {
  width: 16px;
  height: 16px;
}

.p-checkbox {
  width: 16px;
  height: 16px;
}

.client-logo {
  background-image: var(--client-logo-expanded);
}

.client-logo-dark {
  background-image: var(--client-logo-expanded-dark);
}

.client-logo-collapsed {
  background-image: var(--client-logo-collapsed);
}

.dashboard-padding {
  @apply pl-[1.25rem] lg:pl-[3.063rem] pr-[1.25rem] lg:pr-[3.063rem];
}
